<template>
    <div class="datasoures">
        <div style="display: flex;">
            <Headbuttom></Headbuttom>
            <Tabheader @excelExportData="excelExportData" @handFromshow="handFromshow"></Tabheader>
        </div>
        
        <div class="fromshea" v-show="iShow">
            <el-form :inline="true" size="small"  :model="searchParams" class="demo-form-inline">
                <el-form-item :label="this.$t('devtable.lfrm')">
                    <el-input v-model="searchParams.name" :placeholder="this.$t('devtable.lfrm')"></el-input>
                </el-form-item>
                <el-form-item :label="this.$t('devtable.sjhm')">
                    <el-input v-model="searchParams.mobile" :placeholder="this.$t('devtable.sjhm')"></el-input>
                </el-form-item>

                <el-form-item :label="this.$t('devtable.sfr')">
                    <el-input v-model="searchParams.person_name" :placeholder="this.$t('devtable.sfr')"></el-input>
                </el-form-item>
                <el-form-item :label="this.$t('devtable.sfrsjhmS')">
                    <el-input v-model="searchParams.person_mobile" :placeholder="this.$t('devtable.sjhm')"></el-input>
                </el-form-item>

                <el-form-item :label="this.$t('devtable.qsrq')" prop="name">
                    <el-date-picker type="date" :placeholder="this.$t('devtable.xzrq')" v-model="searchParams.start_time" style="width: 100%;"></el-date-picker>
                </el-form-item>
                <el-form-item :label="this.$t('devtable.jsrq')" prop="name">
                    <el-date-picker type="date" :placeholder="this.$t('devtable.xzrq')" v-model="searchParams.end_time" style="width: 100%;"></el-date-picker>
                </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">{{this.$t('devtable.cx')}}</el-button>
            </el-form-item>
        </el-form>
        </div>
        <Tables @handles="handles" @handlpages="handlpages" @handleSizeChange="handleSizeChange" :tableLabel="tableLabel" :tableData="tableData" :flags="flags" :configs="configs"></Tables>
        <Diolg ref="diolg" :dialog="dialog" @handleClose="handleClose" @dialogVisibletrue="dialogVisibletrue">
            <div style="display:flex; justify-content: center;max-height:250px">
                <el-image :src="preview_imgurl" fit="cover"></el-image>
            </div>
        </Diolg>
    </div>
</template>
<script>
//访客记录
import Tables from '@/components/table'
import Tabheader from './components/hebut'
import Diolg from '@/components/dialog/img.vue'
import methodlist from '../../utils/methods'
import {formatDate,timestampToTime} from '../../utils/date'
import xlxs from 'xlsx'
export default {
    components:{
        Tables,
        Tabheader,
        Diolg
    },
    data(){
        return{
            dialog:{
          
                dialogVisible:false,
                title:this.$t('devtable.ylrxzp')
          
            },
            preview_imgurl:"",
            iShow:false,
            searchParams: {
                name: '',
                mobile: '',
                person_name: '',
                person_mobile: '',
                start_time: '',
                end_time: '',
                reg_face_status: '-1'
            },
            centerDialogVisible: false,
           
            tableData: [
                
            ],
            flags: {
                show: true, // 多选是否显示
                ishow: false // 序号是否显示
            },
            // 分页数据
            configs: {
                page_index:1,
                pagesize: 10,
                total: 200,
                loading: false // 加载状态
            },
           UserInfo:''
        }
    },
    computed:{
         tableLabel: function(){
             return[
                {
                    prop: 'name',
                    label: this.$t('devtable.lfrxm'),
                    align: 'center',
					width:110
                },
                {
                    prop: 'mobile',
                    label: this.$t('devtable.sjhm'),
                    align: 'center'
                },
                {
                    prop: 'gender_hint',
                    label: this.$t('devtable.xb'),
                    align: 'center'
                },
                {
                    prop: 'dept_name',
                    label: this.$t('devtable.bfrbmT'),
                    align: 'center',
					width:110
                },
                {
                    prop: 'person_name',
                    label: this.$t('devtable.bfrr'),
                    align: 'center',
					width:110
                },
                {
                    prop: 'visitor_type',
                    label: this.$t('devtable.rylxS'),
                    align: 'center'
                },
                {
                    prop: 'face_url_hint',
                    label: this.$t('devtable.lfrzpD'),
                    align: 'center'
                },
                {
                    prop: 'visit_time_hint',
                    label: this.$t('devtable.lfsj'),
                    align: 'center'
                },
                {
                    prop: 'car_number',
                    label: this.$t('devtable.lfcph'),
                    align: 'center'
                },
                {
                    prop: 'approve_state_hint',
                    label:  this.$t('devtable.shzt'),
                    align: 'center'
                }
            ]
         }
    },
    mounted () {
        this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
        this.getApproveVisitorList(1);
    },
    methods:{
        handleClose(){
            this.$refs.diolg.dialogVisible = false
        },
        dialogVisibletrue(){
            this.$refs.diolg.dialogVisible = false        
        },
        handRegister(){
            this.centerDialogVisible=true
        },
        handFromshow(){
            this.iShow = !this.iShow 
        },
        onSubmit() {
            console.log('submit!');
            this.getApproveVisitorList(1);
        },
        handlpages(v){
            this.configs.page_index=v
            this.getApproveVisitorList()
        },
        handleSizeChange(v){
            this.configs.page_index=1
            this.configs.pagesize=v
            this.getApproveVisitorList()
        },
        //获取访客列表
        getApproveVisitorList(pageIndex){
            const params = {
                method: methodlist.ee_visitor_approve_list,
                agent_id: this.UserInfo.agent_id,
                manager_id: this.UserInfo.manager_id, 
                page_index: pageIndex?pageIndex:this.configs.page_index, 
                page_size: this.configs.pagesize,
                name: this.searchParams.name,
                mobile: this.searchParams.mobile,
                start_time: this.searchParams.start_time.length == 0 ? '0' : this.searchParams.start_time/1000,
                end_time: this.searchParams.end_time.length == 0 ? '0' : this.searchParams.end_time/1000,
                person_name: this.searchParams.person_name,
                person_mobile: this.searchParams.person_mobile,
                reg_face_status: this.searchParams.reg_face_status,
                manager_id:this.UserInfo.manager_id
            }

            this.$serve(params).then(res => {
            console.log(res);
            this.tableData = res.data.data;
            this.tableData.forEach(el=>{
                if(el.gender == 0){
                    el.gender_hint={
                    name: this.$t('devtable.male'),
                    type: 'primary',
                    state: true
                    }
                }else{
                    el.gender_hint={
                    name: this.$t('devtable.female'),
                    type: 'primary',
                    state: true
                    }
                }
                el.visitor_type = {
                    name: this.$t('devtable.fk'),
                    state: true
                }

                if(el.approve_state == 0){
                    el.approve_state_hint ={
                        name: this.$t('devtable.wsh'),
                        state: true,
                        type: 'warning'
                    }
                }else{
                    el.approve_state_hint ={
                        name: this.$t('devtable.ysh'),
                        state: true,
                        type: 'success'
                    }
                }

                el.face_url_hint = {
                    name:this.$t('devtable.yl'),
                    but:true,                                        
                    type:'success'
                }

                if(el.visit_time == 0){
                    el.visit_time_hint = ''
                }else{
                    el.visit_time_hint = {
                        name: ''+formatDate(new Date(el.visit_time * 1000), 'yyyy-MM-dd hh:mm'),
                        type: 'primary',
                        state: true
                    }
                }                
            })
            this.configs.total = res.data.count;
            })
        },
        handles(v){
            console.log(v)
            // return
            this.$refs.diolg.dialogVisible = true
            if(v.face_url.length > 0){
                this.preview_imgurl = methodlist.resident_pic_base + v.face_url +"?tempid="+Math.random()
                this.src = this.preview_imgurl
            }else{
                this.preview_imgurl =' https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg'
            }
                
        },
        //分页获取全部数据
        excelExportData(){
            var exportData = [];
            var pageData = [];
            var allDataTimer=null;
            var postFlag = false;
            var record_index = 1;
            var record_size = 10;
            allDataTimer = setInterval(()=>{
                if(postFlag == false){
                    postFlag=true;
                    const params = {
                        method: methodlist.ee_visitor_approve_list,
                        agent_id: this.UserInfo.agent_id,
                        manager_id: this.UserInfo.manager_id, 
                        page_index: record_index, 
                        page_size: record_size,
                        name: this.searchParams.name,
                        mobile: this.searchParams.mobile,
                        start_time: this.searchParams.start_time.length == 0 ? '0' : this.searchParams.start_time/1000,
                        end_time: this.searchParams.end_time.length == 0 ? '0' : this.searchParams.end_time/1000,
                        person_name: this.searchParams.person_name,
                        person_mobile: this.searchParams.person_mobile,
                        reg_face_status: this.searchParams.reg_face_status,
                        manager_id:this.UserInfo.manager_id
                    }

                    this.$serve(params).then(res => {
                        console.log(res);
                        postFlag = false;
                        
                        pageData = res.data.data;
                        exportData= exportData.concat(pageData);
                        pageData.forEach(el=>{
                            if(el.gender == 0){
                                el.gender_hint={
                                name: this.$t('devtable.male'),
                                type: 'primary',
                                state: true
                                }
                            }else{
                                el.gender_hint={
                                name: this.$t('devtable.female'),
                                type: 'primary',
                                state: true
                                }
                            }
                            el.visitor_type = {
                                name:  this.$t('devtable.fk'),
                                state: true
                            }

                            if(el.approve_state == 0){
                                el.approve_state_hint ={
                                    name:  this.$t('devtable.wsh'),
                                    state: true,
                                    type: 'warning'
                                }
                            }else{
                                el.approve_state_hint ={
                                    name:  this.$t('devtable.ysh'),
                                    state: true,
                                    type: 'success'
                                }
                            }

                            el.face_url_hint = {
                                name: this.$t('devtable.yl'),
                                but:true,                                        
                                type:'success'
                            }

                            if(el.visit_time == 0){
                                el.visit_time_hint = ''
                            }else{
                                el.visit_time_hint = {
                                    name: ''+formatDate(new Date(el.visit_time * 1000), 'yyyy-MM-dd hh:mm'),
                                    type: 'primary',
                                    state: true
                                }
                            } 
                        })
                        
                        if(record_index * record_size >= res.data.count){
						    clearInterval(allDataTimer);
                            allDataTimer = null;
                            console.log(exportData);
                            this.excelExportDataSnd(exportData);
						}else{
						    record_index+=1;
                        }
                    })                    
                }
            },500)
        },        
        excelExportDataSnd(tableData){
            if(window.localStorage.getItem('language')=='en'){
                let arr = tableData.map(item => {
                    return {
                    'Visitors':item.name,
                    'Phone number': item.mobile,
                    'Gender':item.gender_hint.name,
                    'Interviewee Department':item.dept_name,
                    'Interviewee':item.person_name,
                    'Personnel type':item.visitor_type
                    }
                })
            }else{
                let arr = tableData.map(item => {
                    return {
                    '来访人':item.name,
                    '手机号码': item.mobile,
                    '性别':item.gender_hint.name,
                    '被访人部门':item.dept_name,
                    '被访人':item.person_name,
                    '人员类型':item.visitor_type
                    }
                })
            }
            
            // 将数据转换为表格
            let sheet = xlxs.utils.json_to_sheet(arr)
            let book = xlxs.utils.book_new()
            xlxs.utils.book_append_sheet(book, sheet, "sheet1")
            xlxs.writeFile(book, this.$t('navLeft.fkjl')+'-'+`${timestampToTime()}.xls`)
        }                
    }
}
</script>
<style lang="less" scoped>
.datasoures{
    padding: 20px;
    background-color: #fff;
    
    .fromshea{
        margin-top:20px;
        padding: 20px;
    }
}
.tabletemplate{
    padding: 0px;
    padding-top:20px ;
    
}
</style>